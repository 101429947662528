<template>
    <b-form :save-url="url" ref="form" @failure="formFailure" @success="formSuccess(modeMapper[mode])" v-slot="{model, loading}">
        <div class="row">
            <template v-for="(field,i) in fields">
                <div :class="setGridClass(field)" :key="i">
                    <component :is="fieldTags[field.inputType]"
                               :label="field.label"
                               :placeholder="field.placeholder"
                               v-model="model[field.name]"
                               :rules="rules[field.name]"
                               :type="field.type"
                               :options="field.options"
                               :multiple="field.multiple"
                               :input-classes="field.inputClasses"
                               :label-classes="field.labelClasses"
                               :url="field.url"
                               :disabled="loading">
                    </component>
                </div>

                <!--            </template>-->
                <!--            <template v-else>-->
                <!--                <validated-ajax-select :url="field.url"-->
                <!--                                       :label="field.label" :key="i"-->
                <!--                                       v-model="model[field.name]" :rules="rules[field.name]"-->
                <!--                                       :multiple="field.multiple"-->
                <!--                                       :disabled="loading"></validated-ajax-select>-->
                <!--            </template>-->
            </template>
        </div>
        <submit-btn text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                    :loading="loading"/>
    </b-form>
</template>

<script>
export default {
    name: 'PostFormGenerator',
    props: {
        fields: { type: Array, default: null },
        rules: { type: Object, default: null },
        url: { type: String, default: '' },
        mode: { type: String, default: 'add' },
        fieldTags: {
            type: Object,
            default: () => {
                return {
                    text: 'validated-input',
                    select: 'validated-select',
                    'ajax-select': 'validated-ajax-select',
                    'date-picker': 'validated-date-picker',
                    date: 'validated-date-picker',
                    checkbox: 'validated-checkbox',
                    textarea: 'validated-text-area'
                };
            }
        }
    },
    data () {
        return {
            // fieldTags: {
            //     text: 'validated-input',
            //     select: 'validated-select',
            //     'ajax-select': 'validated-ajax-select',
            //     'date-picker': 'validated-date-picker',
            //     date: 'validated-date-picker',
            //     checkbox: 'validated-checkbox',
            //     textarea: 'validated-text-area'
            // },
            modeMapper: {
                add: 'A',
                edit: 'M'
            }
        };
    },
    methods: {
        formSuccess () {
            this.$emit('success');
        },
        formFailure () {
            this.$emit('failure');
        },
        setGridClass (item) {
            const classNames = [];
            if (item.col) {
                classNames.push('col-' + item.col);
            } else {
                classNames.push('col-12');
            }
            if (item['col-md']) {
                classNames.push('col-' + item['col-md']);
            }
            if (item['col-lg']) {
                classNames.push('col-' + item['col-lg']);
            }
            return classNames;
        }
    }
};
</script>

<style scoped>

</style>
