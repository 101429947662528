<template>
    <div>
        <h1 class="text-fs-2">Post Form Generator</h1>
        <container>
            <post-form-generator
                :fields="fields"
                :rules="rules"
                :url="addUrl"
                @success="formSuccess"></post-form-generator>
            <div class="text-fs-1">Add Drug form</div>
            <post-form-generator
                :fields="addDrugFields"
                :rules="drugRules"
                :url="drugAddUrl">
            </post-form-generator>
        </container>
    </div>
</template>

<script>
import PostFormGenerator from './PostFormGenerator';
import urls from '../../data/urls';
export default {
    name: 'ExperimentPage',
    components: { PostFormGenerator },
    data () {
        return {
            fields: [
                {
                    inputType: 'text',
                    label: 'Name',
                    placeholder: 'Category',
                    name: 'category_name'
                }
            ],
            addDrugFields: [
                {
                    inputType: 'ajax-select',
                    label: 'Drug Category',
                    name: 'drug_category',
                    url: urls.drugs.drugCategory.vueSelect,
                    multiple: true,
                    col: '4',
                    inputClasses: 'p-2 mt-2',
                    labelClasses: 'text-primary'
                },
                {
                    inputType: 'text',
                    label: 'Drug Name',
                    name: 'drug_name'
                },
                {
                    inputType: 'textarea',
                    label: 'Drug Action',
                    name: 'drug_action'
                },
                {
                    inputType: 'textarea',
                    label: 'Indications',
                    name: 'indications'
                },
                {
                    inputType: 'textarea',
                    label: 'Dose',
                    name: 'dose'
                },
                {
                    inputType: 'textarea',
                    label: 'Contraindications',
                    name: 'contraindications'
                },
                {
                    inputType: 'textarea',
                    label: 'Available Brands',
                    name: 'available_brands'
                }
            ],
            addUrl: urls.drugs.drugCategory.addEdit,
            drugAddUrl: urls.drugs.drug.addEdit,
            drugCategorySelectUrl: urls.drugs.drugCategory.vueSelect,
            rules: {
                category_name: {
                    required: true
                }
            },
            drugRules: {
                drug_category: {
                    required: true
                },
                drug_name: {
                    required: true
                },
                drug_action: {
                    required: true
                },
                indications: {
                    required: true
                },
                dose: {
                    required: true
                },
                contraindications: {
                    required: true
                },
                available_brands: {
                    required: true
                }
            }
        };
    },
    methods: {
        formSuccess (action) {
            this.$notify('Category Added Successfully', 'Success', {
                type: 'success'
            });
        }
    }
};
</script>

<style scoped>

</style>
